import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import AOS from 'aos'
import 'aos/dist/aos.css'
import i18n from './i18n'
import store from './state/store'

import BootstrapVue3 from 'bootstrap-vue-3'
import vClickOutside from 'click-outside-vue3'
import VueApexCharts from 'vue3-apexcharts'
import Maska from 'maska'

import VueFeather from 'vue-feather'
import * as Sentry from '@sentry/vue'

import '@/assets/scss/config/default/app.scss'
import '@vueform/slider/themes/default.css'
import '@/assets/scss/mermaid.min.css'
import { createPinia } from 'pinia'
import Toast from 'vue-toastification'
import 'vue-toastification/dist/index.css'

AOS.init({
  easing: 'ease-out-back',
  duration: 1000
})

const app = createApp(App)

const pinia = createPinia()

const defaultToastOptions = {
  position: 'top-right',
  closeOnClick: true,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 0.6,
  closeButton: false,
  icon: true,
  rtl: false,
  timeout: 2000
}

Sentry.init({
  app,
  dsn: 'https://ea780cadd74f6b9c8df1be577ee6d199@o4505782950297600.ingest.sentry.io/4505827415228416',
  integrations: [
    new Sentry.BrowserTracing({
      tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
      routingInstrumentation: Sentry.vueRouterInstrumentation(router)
    }),
    new Sentry.Replay()
  ],
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0
})

app.use(store)
  .use(router)
  .use(pinia)
  .use(VueApexCharts)
  .use(BootstrapVue3)
  .component(VueFeather.type, VueFeather)
  .use(Maska)
  .use(i18n)
  .use(Toast, defaultToastOptions)
  .use(vClickOutside)
  .mount('#app')
