import { createWebHistory, createRouter } from 'vue-router'
import axios from 'axios'
import routes from './routes'
import appConfig from '../../app.config'
const router = createRouter({
  history: createWebHistory(),
  routes,
  // Use the HTML5 history API (i.e. normal-looking routes)
  // instead of routes with hashes (e.g. example.com/#/about).
  // This may require some server configuration in production:
  mode: 'history',
  // Simulate native-like scroll behavior when navigating to a new
  // route and using back/forward buttons.
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { top: 0, left: 0 }
    }
  }
})

// API based Authentication
// Before each route evaluates...
router.beforeEach(async (to, from, next) => {
  const authRequired = to.matched.some(route => route.meta.authRequired)

  if (!authRequired) return next()

  axios.defaults.headers.common.authorization = 'Bearer ' + localStorage.getItem('jwt')

  try {
    const response = await axios.get(`${API_BASE_URL}v1/company/my_company`)
    // TODO Внимание супер костыль
    localStorage.setItem('company_id', response.data.id)
    localStorage.setItem('user_id', response.data.user_id)
    localStorage.setItem('company_status', response.data.status)
    localStorage.setItem('xendit_id', response.data.xendit_id)
    parseInt(localStorage.setItem('total_count_rating', response.data.total_count))
    if (response.data.total_count > 0) {
      const averageRating = parseFloat(response.data.average_rating)
      const formattedAverageRating = averageRating.toFixed(2)
      localStorage.setItem('average_rating', formattedAverageRating)
    }
    if (response.data.logo_url) {
      localStorage.setItem('logo_url', response.data.logo_url)
    }
    if (response.data.photo_url) {
      localStorage.setItem('photo_url', response.data.photo_url)
    }
    next()
  } catch {
    next({ name: 'login', query: { redirectFrom: to.fullPath } })
  }
})

router.beforeEach((to, from, next) => {
  const publicPages = ['/login', '/register', '/forgot-password']
  const authRequired = !publicPages.includes(to.path)
  const loggedIn = localStorage.getItem('jwt')

  if (authRequired && !loggedIn) {
    return next({ name: 'login', query: { redirectFrom: to.fullPath } })
  }

  next()
})

router.beforeResolve(async (routeTo, routeFrom, next) => {
  // Create a `beforeResolve` hook, which fires whenever
  // `beforeRouteEnter` and `beforeRouteUpdate` would. This
  // allows us to ensure data is fetched even when params change,
  // but the resolved route does not. We put it in `meta` to
  // indicate that it's a hook we created, rather than part of
  // Vue Router (yet?).
  try {
    // For each matched route...
    for (const route of routeTo.matched) {
      await new Promise((resolve, reject) => {
        // If a `beforeResolve` hook is defined, call it with
        // the same arguments as the `beforeEnter` hook.
        if (route.meta && route.meta.beforeResolve) {
          route.meta.beforeResolve(routeTo, routeFrom, (...args) => {
            // If the user chose to redirect...
            if (args.length) {
              // If redirecting to the same route we're coming from...
              // Complete the redirect.
              next(...args)
              reject(new Error('Redirected'))
            } else {
              resolve()
            }
          })
        } else {
          // Otherwise, continue resolving the route.
          resolve()
        }
      })
    }
    // If a `beforeResolve` hook chose to redirect, just return.
  } catch (error) {
    return
  }
  document.title = routeTo.meta.title + ' | ' + appConfig.title
  // If we reach this point, continue resolving the route.
  next()
})

export default router
