import axios from 'axios'

// Используем значение из vue.config.js

class ApiAuthBackend {
  constructor () {
    this.apiBaseUrl = API_BASE_URL
  }

  /**
     * Registers the user with given details
     */
  registerUser (username, email, password) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axios.post(`${this.apiBaseUrl}/register`, {
          username,
          email,
          password
        })
        sessionStorage.setItem('authUser', JSON.stringify(response.data.user))
        resolve(response.data.user)
      } catch (error) {
        reject(this._handleError(error))
      }
    })
  }

  /**
     * Login user with given details
     */
  loginUser (email, password) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axios.post(`${this.apiBaseUrl}/login`, {
          email,
          password
        })
        sessionStorage.setItem('authUser', JSON.stringify(response.data.user))
        resolve(response.data.user)
      } catch (error) {
        reject(this._handleError(error))
      }
    })
  }

  /**
     * forget Password user with given details
     */
  forgetPassword (email) {
    return new Promise(async (resolve, reject) => {
      try {
        await axios.post(`${this.apiBaseUrl}/forget-password`, {
          email
        })
        resolve(true)
      } catch (error) {
        reject(this._handleError(error))
      }
    })
  }

  /**
     * Logout the user
     */
  logout () {
    return new Promise(async (resolve, reject) => {
      try {
        await axios.post(`${this.apiBaseUrl}/logout`)
        sessionStorage.removeItem('authUser')
        resolve(true)
      } catch (error) {
        reject(this._handleError(error))
      }
    })
  }

  /**
     * Returns the authenticated user
     */
  getAuthenticatedUser () {
    if (!sessionStorage.getItem('authUser')) { return null }
    return JSON.parse(sessionStorage.getItem('authUser'))
  }

  /**
     * Handle the error
     * @param {*} error
     */
  _handleError (error) {
    let errorMessage = error.message
    if (error.response && error.response.data) {
      errorMessage = error.response.data.message
    }
    return errorMessage
  }
}

let _apiBackend = null

/**
 * Initialize the backend
 */
const initApiBackend = () => {
  if (!_apiBackend) {
    _apiBackend = new ApiAuthBackend()
  }
  return _apiBackend
}

/**
 * Returns the API backend
 */
const getApiBackend = () => {
  return _apiBackend
}

export { initApiBackend, getApiBackend }
