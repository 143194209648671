import { getApiBackend } from '../../authUtils.js'

export const state = {
    currentUser: sessionStorage.getItem('authUser'),
}

export const mutations = {
    SET_CURRENT_USER(state, newValue) {
        state.currentUser = newValue
        saveState('auth.currentUser', newValue)
    }
}

export const getters = {
    // Whether the user is currently logged in.
    loggedIn(state) {
        return !!state.currentUser
    }
}

export const actions = {
    init({ state, dispatch }) {
        dispatch('validate')
    },

    logIn({ commit, dispatch, getters }, { email, password } = {}) {
        if (getters.loggedIn) return dispatch('validate')

        return getApiBackend().loginUser(email, password).then((user) => {
            commit('SET_CURRENT_USER', user)
            return user
        });
    },

    logOut({ commit }) {
        commit('SET_CURRENT_USER', null)
        return new Promise((resolve, reject) => {
            getApiBackend().logout().then(() => {
                resolve(true);
            }).catch((error) => {
                reject(error.message);
            })
        });
    },

    register({ commit, dispatch, getters }, { username, email, password } = {}) {
        if (getters.loggedIn) return dispatch('validate')

        return getApiBackend().registerUser(username, email, password).then((user) => {
            commit('SET_CURRENT_USER', user)
            return user
        });
    },

    resetPassword({ dispatch, getters }, { email } = {}) {
        if (getters.loggedIn) return dispatch('validate')

        return getApiBackend().forgetPassword(email).then(() => {
            return true;  // If the request was successful, just return true
        });
    },

    validate({ commit, state }) {
        if (!state.currentUser) return Promise.resolve(null)
        const user = getApiBackend().getAuthenticatedUser();
        commit('SET_CURRENT_USER', user)
        return user;
    },
}

function saveState(key, state) {
    window.sessionStorage.setItem(key, JSON.stringify(state))
}
